.navbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;

  &__container {
    display: block;
    vertical-align: top;
    padding: 30px;
    margin: 0 auto;
    height: auto;
    width: 100%;

    @include respond-to('phone_tablet') {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @include respond-to('phone') {
      padding: 21px 16px;
    }
  }

  &__href {
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    height: 47px;

    &__logo {
      display: block;
      width: 64px;
      height: auto;

      @include respond-to('fullhd') {
        width: 64px;
      }

      &--mobile {
        display: none;
      }
    }
  }

  &__list {
    display: inline-flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
    height: 47px;
    text-align: right;
    float: right;

    & > li {
      margin: 0 1rem;

      &:last-of-type {
        margin: 0;
      }
    }

    &__element {
      display: inline-block;
      vertical-align: top;
      margin-right: 50px;

      &:nth-last-child {
        margin-right: 0;
      }

      @include respond-to('phone') {
        display: none;
      }

      &__href {
        color: $black-color;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-decoration: none;
        transition: 0.25s all;
        text-align: left;

        @include respond-to('phone') {
          font-size: 32px;
          letter-spacing: -0.31px;
          line-height: 53px;
          text-transform: none;
        }

        @include respond-to('fullhd') {
          font-size: 14px;
        }
      }
    }
  }

  &__social {
    display: none;
    margin: 30px 0 17px;

    @include respond-to('phone_tablet') {
      display: inline-flex;
      margin: 46px 0 34px;
    }

    &__elem {
      width: 25px;
      height: 25px;
      margin-right: 15px;
      background-size: 25px 25px !important;

      @include respond-to('phone') {
        margin-right: 30px;
      }

      &--fb {
        background: url('img/common/logo_fb_primary.svg') center no-repeat;
      }

      &--in {
        background: url('img/common/logo_in_primary.svg') center no-repeat;
      }

      &--instagram {
        background: url('img/common/logo_instagram_primary.svg') center no-repeat;
      }

      &--twitter {
        background: url('img/common/logo_twitter_primary.svg') center no-repeat;
      }
    }
  }

  &.active {
    .navbar {
      &__href {
        position: fixed;
        top: 21px;
        left: 16px;
        z-index: 99999;

        @include respond-to('tablet') {
          top: 30px;
          left: 30px;
        }
      }

      &__href__logo {
        z-index: 9999;

        @include respond-to('phone_tablet') {
          display: none !important;

          &--mobile {
            display: block !important;
          }
        }
      }
    }

    .menu-button-container {
      position: fixed;
      right: 20px;
      top: 21px;
      background: transparent;

      @include respond-to('tablet') {
        top: 30px;
      }

      .menu-button {
        background: rgba(255, 255, 255, 0);

        &:before {
          margin-top: 0;
          transform: rotate(405deg);
        }

        &:after {
          margin-top: 0;
          transform: rotate(-405deg);
        }
      }
    }
  }

  &.bright {
    .navbar {
      &__list__element__href {
        color: $white-color;

        &:hover {
          color: $primary-color;
        }

        @include respond-to('phone_tablet') {
          color: $primary-color;
        }

        &.active {
          color: $primary-color;
        }
      }
    }
  }

  &.dark {
    .navbar {
      &__list__element__href {
        color: $black-darker-color;

        &:hover {
          color: $primary-color;
        }

        @include respond-to('phone_tablet') {
          color: $primary-color;
        }

        &.active {
          color: $primary-color;
        }
      }
    }
  }

  &.transparent {
    position: absolute;
    background: transparent;

    .navbar {
      &__list__element__href {
        color: $white-color;
        transition: .25s ease;

        &:hover {
          color: $white-color;
          transition: .25s ease;
        }

        @include respond-to('phone_tablet') {
          color: $primary-color;
        }

        &.active {
          color: $primary-color;
        }
      }
    }

    .menu-button-container {
      color: $white-color;
      border-color: $white-color;
    }

    .menu-button,
    .menu-button::before,
    .menu-button::after {
      background-color: $white-color;
    }
  }
}

.menu-button-container {
  display: none;
  width: 40px;
  height: 40px;
  z-index: 9999;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 2px solid $primary-color;
  padding: 18px;
  border-radius: 50%;
  position: fixed;
  top: 21px;
  right: 20px;

  @include respond-to('tablet') {
    top: 30px;
  }
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  content: '';
  display: block;
  background-color: $primary-color;
  position: fixed;
  height: 2px;
  width: 20px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  margin-top: -5px;
}

.menu-button::after {
  margin-top: 5px;
}

.navbar__list > li {
  display: flex;
  justify-content: left;

  @include respond-to('phone_tablet') {
    display: none;
    width: 100%;
  }
}

@include respond-to('phone_tablet') {
  .menu-button-container {
    display: flex;
  }

  .navbar__list {
    position: absolute;
    top: 0;
    margin-top: 0;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    &__element {
      padding-bottom: 17px !important;

      @include respond-to('phone') {
        padding-bottom: 0 !important;
      }

      &__href {
        font-weight: 800;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: -1px;
        color: $white-color;
      }
    }
  }

  #menu-toggle ~ .navbar__list li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  #menu-toggle:checked ~ .navbar__list {
    background: $white-color;
    height: 100vh;
    height: -webkit-fill-available;
    height: fill-available;
    z-index: 9998;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
  }

  #menu-toggle:checked ~ .navbar__list li {
    height: 50px;
    z-index: 800;
    padding-left: 30px;
    padding-right: 30px;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);

    @include respond-to('phone_tablet') {
      display: flex;
      height: auto;

    }
  }
}

