.footer {
  position: relative;
  z-index: 1;
  height: auto;
  width: 100%;
  background-color: $grey-footer;

  @include respond-to('phone') {
    height: auto;
    padding: 45px 0;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include respond-to('fullhd') {
      margin: 0 100px 0 100px;
    }

    @include respond-to('phone') {
      flex-direction: column;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    width: 100%;
    max-width: 1000px;

    @include respond-to('phone_tablet') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to('phone') {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__logo {
    margin-left: 10px;
    width: 150px;

    @include respond-to('phone') {
      margin: 40px 0 0;
    }

    &__img {
      display: block;
      width: 100%;
      height: auto;

      @include respond-to('phone') {
        display: none;
      }

      &--mobile {
        display: none;

        @include respond-to('phone') {
          display: block;
          width: 185px;
        }
      }
    }
  }

  .box {
    flex: 1;

    @include respond-to('hd') {
      display: flex;
      justify-content: space-around;
    }


    .topic {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: 1px;
      line-height: 26px;

      &--brand {
        font-size: 18px !important;
      }
    }

    .txt {
      margin-top: 26px;

      @include respond-to('phone') {
        margin-top: 15px;
      }

      &__elem {
        margin: 7px 0;
        position: relative;
        display: block;
        font-size: 18px;
        line-height: 30px;
        text-decoration: none;
      }

      a {
        width: fit-content;
        cursor: pointer;
        color: $grey-footer-text;
      }

      &--socials {
        display: flex;

        &__icon {
          align-self: center;
          width: 16px;
          height: 16px;
          margin-right: 13px;

          @include respond-to('phone') {
            display: none;
          }
        }
      }
    }

    .link {
      margin-top: 26px;

      @include respond-to('phone') {
        margin-top: 15px;
      }

      &__elem {
        position: relative;
        display: block;
        font-size: 18px;
        line-height: 30px;
        text-decoration: none;
      }

      a {
        width: fit-content;
        cursor: pointer;
      }
    }
  }
}

.footer--nav {
  padding: 90px 0;

  @include respond-to('phone_tablet') {
    height: auto;
    padding: 70px 0 60px;
  }

  .box {
    @include respond-to('phone') {
      padding-bottom: 30px;
    }

    .topic {
      color: $dark-blue;
    }

    .txt {
      color: $grey-footer-text;

      &__elem {
        color: $grey-footer-text;

        @include respond-to('phone') {
          line-height: 29px;

          &:before {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            width: 15px;
            height: 15px;
          }

          &--company:before {
            background: url('img/common/logo_facture_grey.png') center no-repeat;
            background-size: contain;
          }
          &--address:before {
            background: url('img/common/logo_address_grey.png') center no-repeat;
            background-size: contain;
          }
          //&--in:before {
          //  background: url('img/common/logo_in_grey.png') center no-repeat;
          //  background-size: contain;
          //}
          //&--fb:before {
          //  background: url('img/common/logo_fb_grey.png') center no-repeat;
          //  background-size: contain;
          //}
          //&--instagram:before {
          //  background: url('img/common/logo_instagram_grey.png') center no-repeat;
          //  background-size: contain;
          //}
        }
      }

      a {
        &:hover {
          color: $primary-lighten-color;
        }
      }
    }

    .link {
      color: $primary-color;

      &__elem {
        color: $primary-color;
      }

      a {
        &:hover {
          color: $primary-lighten-color;
        }
      }
    }

  }
}

.footer--links {
  background: transparent;
  padding: 65px 0 60px;

  @include respond-to('phone') {
    transition: all 0.4s ease-in-out;
  }

  .footer__logo {
    @include respond-to('phone') {
      margin: 0;
    }
  }

  .box {
    .topic {
      color: $black-color;
    }

    .txt {
      color: $grey-darker1-color;

      &__elem {
        color: $grey-darker1-color;
      }

      a {
        &:hover {
          color: $black-color;
        }
      }
    }
  }
}
