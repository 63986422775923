/* media query break */

$break-tablet: 767px;
$break-desktop: 1024px;
$break-full-hd: 1440px;

$max-full: 1680px;
$font-Inter: 'Inter', sans-serif;

$white-color: #FFFFFF;
$black-color: #323233;
$black-darker-color: #121212;
$grey-color: #CCCCCC;
$grey-darker1-color: #617594;
$grey-darker2-color: #484851;
$grey-footer: #F8F9FC;
$grey-footer-text: #4F4F4F;

$primary-color: #2A31DA;
$primary-lighten-color: #C4C9FE;
$primary-lighten2-color: #9AA3FE;
$primary-darker-color: #263656;

$dark-blue: #092747;

$red-color: #ff0000;