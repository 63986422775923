@mixin respond-to($media) {
  @if $media == phone {
    @media only screen and (max-width: $break-tablet) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $break-tablet + 1) and (max-width: $break-desktop) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $break-desktop) {
      @content;
    }
  } @else if $media == hd {
    @media only screen and (min-width: $break-full-hd) {
      @content;
    }
  } @else if $media == fullhd {
    @media only screen and (min-width: $max-full) {
      @content;
    }
  } @else if $media == small_mobile {
    @media only screen and (max-width: 374px) {
      @content;
    }
  } @else if $media == small_mobile_height {
    @media only screen and (max-width: 374px) and (max-height: 570px) {
      @content;
    }
  } @else if $media == phone_tablet {
    @media only screen and (max-width: $break-desktop - 1) {
      @content;
    }
  }

}

/* font-size */

@mixin style-element($type) {
  @if $type == h1 {
    font-size: 46px !important;
    font-weight: 800 !important;
    line-height: 55px !important;
    letter-spacing: -1.5px !important;

    @include respond-to('tablet') {
      font-size: 40px !important;
      font-weight: 800 !important;
      line-height: 41px !important;
      letter-spacing: -1.5px !important;
    }

    @include respond-to('phone') {
      font-size: 40px !important;
      font-weight: 800 !important;
      line-height: 48px !important;
      letter-spacing: -1px !important;
    }
  }

  @if $type == h2 {
    font-weight: 800 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    letter-spacing: -.2px;

    @include respond-to('fullhd') {
      font-weight: 800 !important;
      font-size: 50px !important;
      line-height: 60px !important;
      letter-spacing: -.2px !important;
    }

    @include respond-to('tablet') {
      font-weight: 800 !important;
      font-size: 18px !important;
      line-height: 26px !important;
      letter-spacing: -.2px !important;
    }

    @include respond-to('phone') {
      font-weight: 800 !important;
      font-size: 18px !important;
      line-height: 26px !important;
      letter-spacing: -.2px !important;
    }
  }

  @if $type == h3 {
    font-weight: 800 !important;
    font-size: 20px !important;
    line-height: 35px !important;

    @include respond-to('fullhd') {
      font-weight: 800 !important;
      font-size: 24px !important;
      line-height: 35px !important;
      letter-spacing: -0.2px !important;
    }

    @include respond-to('phone') {
      font-weight: 800 !important;
      font-size: 24px !important;
      line-height: 35px !important;
      letter-spacing: -0.2px !important;
    }
  }

  @if $type == h4 {
    font-size: 19px;
    font-weight: 400;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: -0.16px;
  }

  @if $type == 'p' {
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 35px !important;
    letter-spacing: -0.2px !important;

    @include respond-to('tablet') {
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 30px !important;
      letter-spacing: -0.2px !important;
    }
    @include respond-to('phone_tablet') {
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 26px !important;
      letter-spacing: -0.2px !important;
    }
  }

  @if $type == 'blog' {
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;

    @include respond-to('phone') {
      font-size: 17px;
      font-weight: 400;
      line-height: 25px;
    }
  }

  @if $type == 'footer' {
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;

    @include respond-to('tablet') {
      font-size: 16px;
      font-weight: 400;
      line-height: 40px;
    }
  }

  @if $type == 'blog_info' {
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
  }
}

// scrollbar
@mixin scrollbar($width, $bg_track, $bg_handle, $bg_hover) {
  /* width */
  &::-webkit-scrollbar {
    width: $width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $bg_track;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $bg_handle;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $bg_hover;
  }
}