body {
  opacity: 0;
  margin: 0;
  padding: 0;
  font-family: $font-Inter;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: .25s ease;

  &.visible {
    opacity: 1;
    transition: .25s ease;
  }
}

html, body {
  margin: 0;
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.container {
  padding: 0 150px;
  margin: auto;

  @include respond-to('fullhd') {
    padding: 0 160px;
  }
  @include respond-to('tablet') {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
  @include respond-to('phone') {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container-swiper {
  @include respond-to('phone') {
    width: 100%;
    padding: 0 !important;
    margin_bottom: 74px;
  }
}

.margin {
  &__top {
    &--none {
      padding-top: 0 !important;
    }

    &--small {
      padding-top: 70px !important;

      @include respond-to('fullhd') {
        padding-top: 100px !important;
      }

      @include respond-to('tablet') {
        padding-top: 60px !important;
      }

      @include respond-to('phone') {
        padding-top: 30px !important;
      }
    }

    &--big {
      padding-top: 100px !important;

      @include respond-to('fullhd') {
        padding-top: 160px !important;
      }
      @include respond-to('tablet') {
        padding-top: 90px !important;
      }
      @include respond-to('phone') {
        padding-top: 50px !important;
      }
    }

    &--huge {
      padding-top: 160px !important;

      @include respond-to('fullhd') {
        padding-top: 160px !important;
      }
      @include respond-to('tablet') {
        padding-top: 150px !important;
      }
      @include respond-to('phone') {
        padding-top: 110px !important;
      }
    }
  }

  &__bottom {
    &--none {
      padding-bottom: 0 !important;
    }

    &--small {
      padding-bottom: 70px !important;

      @include respond-to('fullhd') {
        padding-bottom: 100px !important;
      }

      @include respond-to('tablet') {
        padding-bottom: 60px !important;
      }

      @include respond-to('phone') {
        padding-bottom: 30px !important;
      }
    }

    &--big {
      padding-bottom: 100px !important;

      @include respond-to('fullhd') {
        padding-bottom: 160px !important;
      }
      @include respond-to('tablet') {
        padding-bottom: 90px !important;
      }
      @include respond-to('phone') {
        padding-bottom: 50px !important;
      }
    }

    &--huge {
      padding-bottom: 160px !important;

      @include respond-to('fullhd') {
        padding-bottom: 160px !important;
      }
      @include respond-to('tablet') {
        padding-bottom: 150px !important;
      }
      @include respond-to('phone') {
        padding-bottom: 110px !important;
      }
    }
  }
}

.btn-cta {
  position: relative;
  z-index: 9990;
  display: table;
  padding: 17px 39px;
  width: fit-content;
  width: -moz-max-content;
  border-radius: 3px;
  font-family: $font-Inter;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: $white-color;
  transition: all 0.25s ease;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: .5;
    cursor: auto;
  }

  &--primary {
    background: $primary-color;
  }

  &--dark-grey {
    background: $grey-darker2-color;
  }

  &--white {
    background: $white-color;
    color: $black-color;
  }

  @include respond-to('phone') {
    z-index: 999;
  }
}

.container-main {
  padding: 0 50px;

  @include respond-to('hd') {
    padding: 0 180px;
  }

  @include respond-to('tablet') {
    width: 90%;
  }

  @include respond-to('phone') {
    width: calc(100% - 70px);
    padding: 0;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// Textarea auto resize
.textarea-autosize {
  width: 100%;
  max-width: 500px;
  min-height: 100px;
  overflow: hidden;
  margin-top: 10px;
  padding: 10px 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: $black-darker-color;
  font-family: $font-Inter;
  font-size: 18px;
  line-height: 28px;
  outline: none;
}

.body .formWrap p, body .formButtonWrap p, body .formWrap h4, body .formButtonWrap h4 {
  color: red !important;
}

.breadcrumbs {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: block;
  clear: both;

  background: #191A1B;

  ul {
    li {
      display: inline-block;
      box-sizing: border-box;
      font-size: 13px;
      color: #a1a2a2;
      margin-right: 10px;
      //text-transform: uppercase;

      &:last-child {
        text-decoration: underline;
      }

      a {
        font-size: 12px;
        color: #a1a2a2;
        letter-spacing: 0;
        line-height: 30px;

      }
    }
  }
}

.version {
  display: inline-block;
  vertical-align: top;
  float: left;
  margin-top: 6px;
  margin-right: 7px;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
}

.info-box {
  width: 100%;
  height: 60px;
  position: absolute;
  top: calc(100vh - 60px);
  background: #31312f;
  color: #fff;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 15px;
  z-index: 5;

  a {
    color: #E4A044;
  }

}

.block-body_full_box {
  img {
    width: 80%;
    margin-left: 10%;
    height: 100%;
    float: left;
  }
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;

}

.close:hover {
  opacity: 1;
}

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
  @include respond-to('phone') {
    background-color: #fff;
  }
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.mask-top {
  @include respond-to('phone') {
    position: absolute !important;
  }
}

.iframe-container {
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.logo-absolute {
  position: fixed !important;
}

.fit-container {
  @include respond-to('phone') {
    padding: 0 !important;
  }
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}
