.script-top {
  width: 100%;
  position: relative;
  overflow: hidden;
  top: 0;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  @include respond-to('phone') {
    background-position: bottom;

  }

  .container {
    position: relative;

    @include respond-to('fullhd') {
      padding-bottom: 160px !important;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 1s all;
  }

  &__box {
    max-width: 605px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: top;
    min-height: 630px;
    align-content: center;
    flex-wrap: wrap;

    @include respond-to('fullhd') {
      max-width: 640px;
    }

    @include respond-to('phone') {
      justify-content: left;
      width: 100%;
      min-height: unset;
    }

    &__topic {
      @include style-element('h1');
      position: relative;
      width: 100%;
      z-index: 3;
      color: $black-color !important;

      @include respond-to('phone') {
        word-break: break-word !important;
      }

      @include respond-to('fullhd') {
        font-size: 72px !important;
        line-height: 73px !important;
        letter-spacing: -1px !important;
      }
    }

    &__type {
      z-index: 1;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.2px;
      color: $black-color;
      margin-top: 51px;
      max-width: 468px;

      @include respond-to('fullhd') {
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
      }

      @include respond-to('tablet') {
        margin-top: 25px;
        font-size: 18px;
        line-height: 24px;
      }

      @include respond-to('phone') {
        margin-top: 19px;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .btn-cta {
      margin-top: 51px;

      @include respond-to('tablet') {
        margin-top: 25px;
      }

      @include respond-to('phone') {
        margin-top: 19px;
      }

    }

    &__image {
      position: absolute;
      display: block;
      width: 578px;
      height: 616px;
      background-repeat: no-repeat;
      max-width: calc(100% - 847px);

      @include respond-to('phone') {
        max-width: 100%;
      }
    }
  }

  &__video {
    width: 100%;
    height: 100%;
    position: absolute;
    //z-index: -1;

    @include respond-to('phone') {
      display: none;
    }

    &--mobile {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      //z-index: -1;

      @include respond-to('phone') {
        display: block;
      }
    }
  }

  &--left {
    padding-bottom: 30px;

    @include respond-to('phone') {
      padding-bottom: 0;
    }

    .script-top__box {
      @include respond-to('phone') {
        //margin-bottom: 320px;
        padding-bottom: 20px;
        margin-top: 97px;
      }

      &__image {
        right: 92px;
        background-size: contain;

        @include respond-to('phone') {
          position: relative;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 320px;
          background-position-x: center;
          background-size: cover;
        }
      }
    }
  }

  &--right {
    .script-top__box {
      margin-left: auto;

      @include respond-to('phone') {
        display: grid;
        grid-template-areas: 'image' 'title' 'desc';
      }

      &__topic {
        @include respond-to('phone') {
          grid-area: title;
          padding-bottom: 35px;
        }
      }

      &__type {
        @include respond-to('phone') {
          grid-area: desc;
          margin-top: 0;
        }
      }

      &__image {
        left: 92px;
        background-size: contain;

        @include respond-to('phone') {
          grid-area: image;
          position: relative;
          top: 0;
          left: 0;
          height: 320px;
          width: 100%;
          margin-bottom: 75px;
          background-size: contain;
          background-position-x: center;
        }
      }
    }
  }

  // do sprawdzenia
  .webgl {
    canvas {
      position: absolute;
      right: 0;
      top: 0;
      width: 100% !important;
      height: 100%;
      z-index: 1;
    }
  }

  &__box__image {
    &--desktop {
      @include respond-to('phone_tablet') {
        display: none;
      }
    }

    &--mobile {
      position: inherit !important;
      width: 100% !important;
      height: 100% !important;

      @include respond-to('desktop') {
        display: none;
      }
    }
  }
}
