.slide-top {
  width: 100%;
  position: relative;
  overflow: hidden;
  top: 0;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  @include respond-to('phone') {
    background-position: bottom;

  }

  .container {
    position: relative;

    @include respond-to('fullhd') {
      padding-bottom: 160px !important;
    }

    @include respond-to('tablet') {
      width: calc(100% - 100px);
    }

    @include respond-to('phone') {
      width: calc(100% - 60px);
    }
  }

  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        overflow: hidden;

        @include respond-to('phone') {
          padding-bottom: 60px;
        }
      }
    }

    .swiper-pagination {
      bottom: 24px;

      .swiper-pagination-bullet {
        background: $primary-lighten-color;
        opacity: .5;
        width: 12px;
        height: 12px;
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: -1.5px;
    color: $white-color;

    @include respond-to('phone') {
      font-size: 25px;
      line-height: 32px;
      letter-spacing: -1px;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.2px;
    color: $grey-color;
    margin-top: 50px;
    max-width: 470px;

    @include respond-to('phone') {
      font-size: 18px;
      line-height: 26px;
      color: $white-color;
      margin-top: 20px;
    }
  }

  &__btn {
    margin-top: 50px;

    @include respond-to('phone') {
      margin-top: 15px;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 1s all;
  }

  &__wrapper {
    display: grid;
    grid-gap: 5%;

    @include respond-to('phone') {
      grid-gap: 50px;
    }

    &__box {
      &--image {
        grid-area: image;
        position: relative;
      }

      &--text {
        grid-area: text;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &__image {
    @include respond-to('phone') {
      width: 100%;
      height: auto;
    }

    &--desktop {
      display: block;

      @include respond-to('phone') {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include respond-to('phone') {
        display: block;
      }
    }
  }

  &__video {
    width: 100%;
    height: 100%;
    position: absolute;

    @include respond-to('phone') {
      display: none;
    }

    &--mobile {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;

      @include respond-to('phone') {
        display: block;
      }
    }
  }

  &--left {
    .container {

      @include respond-to('phone') {
        padding-bottom: 0 !important;
      }
    }

    .script-top {
      &__wrapper {
        grid-template-columns: minmax(600px, 35%) minmax(calc(95% - 600px), 60%);
        grid-template-areas: 'text image';

        @include respond-to('tablet') {
          grid-template-columns: minmax(400px, 35%) minmax(calc(95% - 400px), 60%);
        }

        @include respond-to('phone') {
          grid-template-columns: 1fr;
          grid-template-areas: 'text' 'image';
        }

        &__box {
          &--image .script-top__image {
            float: left;
          }
        }
      }
    }
  }

  &--right {
    .script-top {
      &__wrapper {
        grid-template-columns: minmax(calc(95% - 600px), 60%) minmax(600px, 35%);
        grid-template-areas: 'image text';

        @include respond-to('tablet') {
          grid-template-columns: minmax(calc(95% - 400px), 60%) minmax(400px, 35%);
        }

        @include respond-to('phone') {
          grid-template-columns: 1fr;
          grid-template-areas: 'image' 'text';
        }

        &__box {
          &--image .script-top__image {
            float: right;
          }
        }
      }
    }
  }

  // do sprawdzenia
  .webgl {
    canvas {
      position: absolute;
      right: 0;
      top: 0;
      width: 100% !important;
      height: 100%;
      z-index: 1;
    }
  }
}


.script-top-background {
  background-size: cover;
  background-repeat: no-repeat;

  &.height-full {
    height: 100vh;
  }
}