.grid-image-text {
  &__title {
    font-weight: bold;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: -1.5px;
    color: $black-color;

    @include respond-to('phone') {
      font-size: 25px;
      line-height: 32px;
      letter-spacing: -1px;
    }
  }

  &__text {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.2px;
    color: $black-color;
    margin-top: 45px;
    max-width: 640px;

    @include respond-to('phone') {
      font-size: 18px;
      line-height: 26px;
      margin-top: 18px;
    }
  }

  &__cta {
    margin-top: 50px;

    @include respond-to('phone') {
      margin-top: 15px;
    }
  }

  &__image {
    position: relative;
    transition: .25s ease;

    img {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: -1;
      transition: .25s ease;

      @include respond-to('phone') {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-areas: 'image text';
    grid-gap: 100px;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
      grid-template-areas: 'image' 'text';
      grid-gap: 30px;
    }

    &__box {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--image {
        grid-area: image;
      }

      &--text {
        grid-area: text;
        max-width: 530px;
      }
    }
  }

  &.reverse {
    .grid-image-text {
      &__grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 'text image';

        @include respond-to('phone') {
          grid-template-columns: 1fr;
          grid-template-areas: 'text' 'image';
        }

        &--text {
          max-width: 100%;
        }
      }

      &__image img {
        left: 0;
        right: unset;
      }
    }
  }
}