.minimal-top {
  position: relative;
  width: 100%;
  color: $black-color;

  &--h2 {
    h2 {
      @include style-element('h1');
      @include respond-to('phone') {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -1px;
      }
    }

    @include respond-to('phone') {
      .btn-cta {
        width: 100%;
      }
    }
  }

  &--p {
    p {
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.2px;
      margin-top: 26px;

      @include respond-to('phone') {
        font-size: 18px;
        line-height: 26px;
        margin-top: 17px;
      }
    }
  }

  .btn-cta {
    height: fit-content;
    min-width: 100px;
    padding: 17px 28px;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.8px;

    @include respond-to('phone') {
      margin-top: 45px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;

    @include respond-to('phone') {
      flex-direction: column;
    }
  }

  .container--main {
    position: relative;
    margin-left: 0;
    max-width: 685px;

    @include respond-to('fullhd') {
      max-width: 765px;
    }

    @include respond-to('tablet') {
      max-width: 565px;
    }

    @include respond-to('phone') {
      width: 100%;
    }
  }
}
