.blog-list {
  &__title {
    font-weight: bold;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: -1.5px;
    color: $black-color;

    @include respond-to('phone') {
      font-size: 32px;
      line-height: 32px;
      letter-spacing: -1px;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.2px;
    color: $black-color;
    margin-top: 30px;
    max-width: 640px;

    @include respond-to('phone') {
      font-size: 18px;
      line-height: 26px;
      margin-top: 20px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 64px 24px;
    margin-top: 100px;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
    }
  }

  &__box {
    transition: .25s ease;

    @include respond-to('desktop') {
      &:hover {
        transform: scale(1.02);
        transition: .25s ease;
      }
    }

    &__image {
      width: 100%;
      min-height: 100px;
      max-height: 500px;
      transition: .25s ease;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .25s ease;
      }
    }

    &__title,
    &__title a {
      font-weight: 600;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: -0.2px;
      color: $black-color;
      display: block;
      margin-top: 19px;

      &:hover {
        text-decoration: underline;
      }

      @include respond-to('phone') {
        font-size: 18px;
        line-height: 26px;
        margin-top: 15px;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: $black-color;

      &--date {
        margin-top: 32px;

        @include respond-to('phone') {
          margin-top: 20px;
        }
      }

      &--desc {
        margin-top: 23px;

        @include respond-to('phone') {
          margin-top: 13px;
        }
      }
    }

    &__cta {
      margin-top: 28px;

      @include respond-to('phone') {
        margin-top: 22px;
      }
    }
  }
}